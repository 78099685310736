@use "../util" as *;

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include breakpoint(medium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
    }
  }

  &__subtitle {
    font-size: rem(14);
    font-weight: 700;
    color: var(--text-color2);
  }
}
