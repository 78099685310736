@use "../util" as *;

.container {
  padding: 10 rem(25);
  width: rem(310);
  margin: rem(40) rem(50);
  @include breakpoint(medium) {
    width: rem(560);
  }

  @include breakpoint(large) {
    width: rem(1045);
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
